import NavLinks from "./NavLinks";
 
const Header = () => {
  return (
    <header className="header">
        
      <NavLinks />
    </header>
  );
};

export default Header;
