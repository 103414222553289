import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: " Hello I'm Evaldas Skackauskas",
    location: "Kaunas, Lithuania",
    tagline: "I'm junior full-stack developer, who always looking for self improvement.",
    email: "skaefas@gmail.com",
    availability: "Open for work",
    brand:
      "I have decided to change my career path recently, so I have learnt programming. I am looking for an internship as a front end or back end programmer. The languages include:PHP, Laravel Framework, JavaScript and React. I like to study independently and problem finding and fixing the code are the challenges that I am willing to take.",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
